import { useForm } from "react-hook-form"

import { TeamScore } from "../team/TeamLabel"
import GameParty from "./GameParty"

interface Props {
    round: number
    teams: [TeamScore, TeamScore][]
    onTeamScoreUpdate: (teamNumber: number, score: number | null) => void
    onValidate: () => void
}

export default function Round(props: Props) {
    const { register, handleSubmit } = useForm<number[]>()

    return (
        <>
            <h1 className="mb-4 text-center text-4xl">Manche {props.round}</h1>

            <form
                onSubmit={handleSubmit((data) => {
                    for (let teamNumber in data) {
                        props.onTeamScoreUpdate(parseInt(teamNumber), isNaN(data[teamNumber]) ? null : data[teamNumber])
                    }
                    props.onValidate()
                })}
            >
                <div className="flex flex-col items-center">
                    {props.teams.map(([teamA, teamB]) => (
                        <GameParty
                            key={`${teamA.number}-${teamB.number}`}
                            teamA={teamA}
                            teamB={teamB}
                            fieldRegisterer={register}
                        />
                    ))}
                </div>

                <div className="mt-2 text-center">
                    <button type="submit" className="btn btn-primary">
                        Terminer la manche
                    </button>
                </div>
            </form>
        </>
    )
}
