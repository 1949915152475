import clsx from "clsx"

import TeamLabel from "../team/TeamLabel"

interface Props {
    number: number
    score: number | null
    rank?: "first" | "second" | "third"
}

export default function Team(props: Props) {
    return (
        <div
            className={clsx("my-2 rounded-xl shadow-md", {
                "my-8 scale-150 bg-yellow-400": props.rank === "first",
                "my-6 scale-125 bg-zinc-400": props.rank === "second",
                "my-2 scale-110 bg-amber-800": props.rank === "third",
                "bg-slate-100": props.rank === undefined,
            })}
        >
            <TeamLabel {...props} />
        </div>
    )
}
