import { TeamScore } from "../team/TeamLabel"
import Team from "./Team"

interface Props {
    teams: TeamScore[]
}

export default function Ranking(props: Props) {
    return (
        <>
            <h1 className="mb-4 text-center text-4xl">Classement</h1>

            <div className="flex flex-col items-center">
                <Team rank="first" number={props.teams[0].number} score={props.teams[0].score} />
                <Team rank="second" number={props.teams[1].number} score={props.teams[1].score} />
                <Team rank="third" number={props.teams[2].number} score={props.teams[2].score} />
                {props.teams.slice(3).map((team) => (
                    <Team key={team.number} number={team.number} score={team.score} />
                ))}
            </div>
        </>
    )
}
