import { useForm } from "react-hook-form"

interface Props {
    onSubmit: (quantity: number) => void
}

export default function TeamQuantityForm(props: Props) {
    const { register, handleSubmit } = useForm<{ quantity: number }>()

    return (
        <section>
            <form
                onSubmit={handleSubmit(({ quantity }) => {
                    props.onSubmit(quantity)
                })}
            >
                <div className="form-control w-full max-w-xs">
                    <label className="label justify-center">
                        <span className="label-text text-xl">Combien y-a-t'il d'équipes&nbsp;?</span>
                    </label>
                    <input
                        {...register("quantity", { valueAsNumber: true })}
                        type="number"
                        step={2}
                        placeholder="Nombre d'équipes"
                        className="input input-bordered w-full max-w-xs"
                        autoFocus
                    />
                </div>
                <div className="mt-2 text-center">
                    <button className="btn btn-primary">Valider</button>
                </div>
            </form>
        </section>
    )
}
