import { UseFormRegisterReturn } from "react-hook-form"

interface Props extends TeamScore {
    editMode?: boolean
    inputProps?: UseFormRegisterReturn
}

export interface TeamScore {
    number: number
    previousScore?: number | null
    score: number | null
}

export default function TeamLabel(props: Props) {
    return (
        <div className="flex w-52 flex-col rounded-xl px-4 py-2 text-2xl">
            <span>
                Équipe <span className="font-semibold text-slate-700">{props.number}</span>
                {props.previousScore && (
                    <>
                        &nbsp;:{" "}
                        <span className="text-lg text-slate-400">
                            {displayScore(props.previousScore)}
                            <span className="text-sm"> pts</span>
                        </span>
                    </>
                )}
            </span>
            {props.editMode ? (
                <input
                    {...props.inputProps}
                    type="number"
                    className="input input-bordered w-full max-w-xs"
                    defaultValue={props.score ?? ""}
                />
            ) : (
                <span className="px-2 py-2 text-center text-4xl font-bold">{displayScore(props.score)}</span>
            )}
        </div>
    )
}

const IntegerFormatter = new Intl.NumberFormat()
function displayScore(score: number | null): string {
    if (score === null) {
        return "-"
    }

    return IntegerFormatter.format(score)
}
