import { UseFormRegister } from "react-hook-form"

import TeamLabel, { TeamScore } from "../team/TeamLabel"

interface Props {
    teamA: TeamScore
    teamB: TeamScore
    fieldRegisterer: UseFormRegister<number[]>
}

export interface FormData {
    scoreTeamA: number
    scoreTeamB: number
}

export default function GameParty(props: Props) {
    return (
        <div className="my-2 flex w-fit rounded-xl bg-slate-100 shadow-md">
            <TeamLabel
                {...props.teamA}
                editMode
                inputProps={props.fieldRegisterer(`${props.teamA.number}`, { valueAsNumber: true })}
            />
            <span className="mx-8 my-auto scale-y-150  text-4xl font-extrabold text-slate-200">VS</span>
            <TeamLabel
                {...props.teamB}
                editMode
                inputProps={props.fieldRegisterer(`${props.teamB.number}`, { valueAsNumber: true })}
            />
        </div>
    )
}
