import { useCallback, useMemo, useState } from "react"

import Ranking from "./ranking/Ranking"
import Round from "./round/Round"
import { TeamScore } from "./team/TeamLabel"
import TeamQuantityForm from "./team/TeamQuantityForm"

interface Team {
    number: number
    firstRoundScore: number | null
    secondRoundScore: number | null
}

function useGlobalState() {
    const [teams, setTeams] = useState<Team[]>([])
    const [currentRound, setCurrentRound] = useState(0)

    const roundTeams = useMemo<[TeamScore, TeamScore][]>(() => {
        const roundTeams: [TeamScore, TeamScore][] = []
        for (let i = 0; i < teams.length / 2; ++i) {
            const teamA = teams[2 * i]
            const teamB = teams[2 * i + 1]
            roundTeams.push([
                {
                    number: teamA.number,
                    previousScore: currentRound === 1 ? undefined : teamA.firstRoundScore,
                    score: currentRound === 1 ? teamA.firstRoundScore : teamA.secondRoundScore,
                },
                {
                    number: teamB.number,
                    previousScore: currentRound === 1 ? undefined : teamB.firstRoundScore,
                    score: currentRound === 1 ? teamB.firstRoundScore : teamB.secondRoundScore,
                },
            ])
        }

        return roundTeams
    }, [teams, currentRound])
    const rankedTeams = useMemo<TeamScore[]>(() => {
        if (currentRound !== 3) {
            return []
        }

        const rankedTeams = teams.map((team): { number: number; score: number } => ({
            number: team.number,
            score: (team.firstRoundScore ?? 0) + (team.secondRoundScore ?? 0),
        }))
        rankedTeams.sort((a, b) => {
            if (a.score === b.score) {
                return a.number - b.number
            }

            return b.score - a.score
        })

        return rankedTeams
    }, [currentRound])

    return {
        currentRound,
        terminateRound: useCallback(() => {
            setTeams((teams) => {
                teams.sort((a, b) => {
                    const scoreA = (a.firstRoundScore ?? 0) + (a.secondRoundScore ?? 0)
                    const scoreB = (b.firstRoundScore ?? 0) + (b.secondRoundScore ?? 0)

                    if (scoreA === scoreB) {
                        return a.number - b.number
                    }

                    return scoreB - scoreA
                })

                return [...teams]
            })
            setCurrentRound((round) => round + 1)
        }, []),
        setTeamQuantity: useCallback((quantity: number) => {
            if (quantity % 2 !== 0) {
                throw new Error(`Expected an even number. Got ${quantity}.`)
            }

            setTeams(
                new Array(quantity).fill(null).map(
                    (_, index): Team => ({
                        number: index + 1,
                        firstRoundScore: null,
                        secondRoundScore: null,
                    }),
                ),
            )
            setCurrentRound(1)
        }, []),
        roundTeams,
        setTeamScore: useCallback(
            (teamNumber: number, score: number | null) => {
                setTeams((teams) => {
                    const teamIndex = teams.findIndex((team) => team.number === teamNumber)
                    if (teamIndex === -1) {
                        throw new Error(`Team ${teamNumber} not found.`)
                    }

                    return arrayReplaceElement(teams, teamIndex, {
                        ...teams[teamIndex],
                        firstRoundScore: currentRound === 1 ? score : teams[teamIndex].firstRoundScore,
                        secondRoundScore: currentRound === 2 ? score : teams[teamIndex].secondRoundScore,
                    })
                })
            },
            [currentRound],
        ),
        rankedTeams,
    }
}

function arrayReplaceElement<T>(array: T[], index: number, element: T): T[] {
    if (index < 0 || index >= array.length) {
        throw new Error("Out of bound")
    }

    return [...array.slice(0, index), element, ...array.slice(index + 1)]
}

export default function App() {
    const { currentRound, terminateRound, setTeamQuantity, roundTeams, setTeamScore, rankedTeams } = useGlobalState()

    return (
        <div className="mx-auto w-fit">
            {currentRound === 0 ? (
                <TeamQuantityForm onSubmit={setTeamQuantity} />
            ) : currentRound === 3 ? (
                <Ranking teams={rankedTeams} />
            ) : (
                <Round
                    key={currentRound}
                    round={currentRound}
                    teams={roundTeams}
                    onTeamScoreUpdate={setTeamScore}
                    onValidate={terminateRound}
                />
            )}
        </div>
    )
}
